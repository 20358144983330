import React, { Component } from 'react';
import moment from 'moment';

class Table extends Component {

    handleLoop = () => {
        const {data, agent_kpi, date} = this.props;
        let list = (
            <tr>
                <td colSpan={5} className="valign-middle tx-center">No Data Found</td>
            </tr>
        )

        if (data && data.length > 0) {
            let date_from = "",
                date_to = "";

            if (date.length > 0) {
                date_from = moment(date[0]).format('YYYY-MM-DD');
                date_to = moment(date[1]).format('YYYY-MM-DD');
            }

            list = data.map((dt, idx) =>
                <tr key={idx}>
                    <td className="valign-middle tx-center">{idx + 1}</td>
                    <td className="valign-middle tx-center">{dt.agent_email}</td>
                    <td className="valign-middle tx-center">{dt.total_reply}</td>
                    {/* <td className="valign-middle tx-center">{dt.total_uniq_reply}</td> */}
                    <td className="valign-middle tx-center">
                        <button 
                            // data-toggle="modal" data-target="#modal-kpi"
                            className="btn btn-sm btn-secondary"
                            onClick={() => agent_kpi(dt.agent_email, date_from, date_to)}
                        >Detail</button>
                    </td>
                </tr>
            )
        }

        return list;
    }

    render() {
        return (
            <div className="card card-table">
                <div className="card-header">
                    <h6 className="slim-card-title">TICKET HISTORY</h6>
                </div>
                <div className="table-responsive">
                    <table className="table mg-b-0 tx-13">
                        <thead>
                            <tr className="tx-10">
                                <th>NO</th>
                                <th>AGENT EMAIL</th>
                                <th>TOTAL REPLY</th>
                                {/* <th>TOTAL TICKET</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.handleLoop()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Table;