import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

class Pagination extends Component {
    render() {
        const {pageCount, currentPage, handleChange} = this.props
        return (
            <ReactPaginate 
                pageCount={pageCount} 
                breakLabel={<span className="page-link">...</span>}
                breakClassName={"page-item disabled"}
                containerClassName={"pagination ml-auto mb-0"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                previousLabel={"<"}
                nextLabel={">"}
                disabledClassName={"disabled"}
                forcePage={currentPage}
                onPageChange={handleChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
            />
        );
    }
}

export default Pagination;