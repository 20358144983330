import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

class Search extends Component {
    state = {
        ticket_number: ""
    }

    handleChange = (e) => {
        if (e.key === "Enter") {
            this.handleSubmit(e)
        }
        else
        {
            this.setState({
                ticket_number: e.currentTarget.value
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {get_ticket, change_nav} = this.props;
        let el = document.querySelector('#all'),
            other = document.querySelectorAll('#TopNav .nav-link');
        
        get_ticket({
            query: this.state.ticket_number,
            ticket_type: 'all',
            status: ""
        })
        
        if (el) {
            other.forEach(element => {
                element.classList.remove('active');
            });
            el.classList.add('active');
        }

        change_nav(2);
        this.props.history.push("/ticket/all")
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="search-box">
                    <input 
                        className="form-control ticket_number" 
                        placeholder="Ticket Number" 
                        name="ticket_number" 
                        type="text" 
                        onKeyUp={this.handleChange} 
                        style={{
                            borderRadius: "50px"
                        }}
                    />
                    <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </form>
        );
    }
}

export default withRouter(Search);