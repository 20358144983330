import axios from 'axios';
import { map_err, toggle_loader } from './main';

const $ = window.$;

export const reset_data = (key, data) => ({
    type: "RESET_STATE_BULK",
    key,
    data
})

export const put_data = (key, data) => ({
    type: "PUT_DATA_BULK",
    key,
    data
})

export const batch_add_conversation = (data, ref = null) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post(`otrs/batch_add_conversation/`, data)
        .then(resp => {
            setTimeout(() => {
                alert(resp.data.msg)

                // Clear Form & State
                $('#bulk_reply textarea').val('');
                $('#bulk_reply #status-select').val('');
                ref.setText('');

                dispatch(reset_data());
            }, 300);
        })
        .catch(err => {
            setTimeout(() => {
                dispatch(map_err(err));
            }, 300);
        })
        .then(() => {
            
            dispatch(toggle_loader(false));
        })
    }
}

