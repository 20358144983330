export const reset_state = {
    sort_type: "", 
    sort_by: "", 
    date_from: "", 
    date_to: "", 
    category: "", 
    base: "ticket_number", 
    query: "", 
    page: 1,
    title: "",
    from: "",
    convo: "",
}

const ticket_state = {
    ticket_data: false,
    total_data: 0,
    total_page: 0,
    current_page: 0,
    ticket_detail: false,
    conversation: false,

    open_modal_detail: false,

    ticket_type: "all", 
    sort_type: "", 
    sort_by: "", 
    date_from: "", 
    date_to: "", 
    status: "", 
    category: "", 
    base: "ticket_number", 
    query: "", 
    page: 1,
    title: "",
    from: "",
    convo: "",
}

const ticket = (state = ticket_state, action) => {
    switch (action.type) {
        case "APPEND_CHAT":
            let {data} = action.data;
            
            let obj = {
                created_at : data.created_at,
                content : data.content,
                agent_email : null,
                user_open: {
                    nickname: state.ticket_detail.user_open.nickname
                }
            }

            let conv_copy = [
                ...state.conversation
            ]

            conv_copy.unshift(obj);

            return {
                ...state,
                conversation: conv_copy
            }
        case "PUT_DATA":
            return {
                ...state,
                [action.key]: action.data
            }
        case "MAP_DATA":
            return {
                ...state,
                ...action.data
            }
        case "TOGGLE_MODAL":
            return {
                ...state,
                open_modal_detail: !state.open_modal_detail
            }
		default:
			return state;
	}
};

export default ticket;