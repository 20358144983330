import axios from 'axios';
import { map_err, toggle_loader } from './main'

const $ = window.$;

export const put_data_kpi = (key, data) => ({
    type: "PUT_DATA_KPI",
    key, data
})

export const all_kpi = (date_from="", date_to="") => {
    return dispatch => {
        dispatch(toggle_loader(true));
        
        axios.get(`otrs/kpi/?date_from=${date_from}&date_to=${date_to}`)
        .then(resp => {
            dispatch(put_data_kpi('all_kpi', resp.data.agent_kpi))
            dispatch(put_data_kpi('total_reply', resp.data.total_reply))
            dispatch(put_data_kpi('total_uniq_reply', resp.data.total_uniq_reply))
        })
        .catch(err => {
            dispatch(map_err(err))
        })
        .then(() => {
            dispatch(toggle_loader(false));
        })
    }
}

export const agent_kpi = (agent_email, date_from, date_to, own=false) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.get(`otrs/kpi/${agent_email}/?date_from=${date_from}&date_to=${date_to}`)
        .then(resp => {
            dispatch(put_data_kpi('agent_name_detail', agent_email));
            dispatch(put_data_kpi('agent_kpi', resp.data));
            dispatch(put_data_kpi('total_reply', resp.data.total_reply))
            dispatch(put_data_kpi('total_uniq_reply', resp.data.total_uniq_reply))
            if (!own) {
                $('#modal-kpi').modal('show');
            }
        })
        .catch(err => {
            dispatch(map_err(err))
        })
        .then(() => {
            dispatch(toggle_loader(false));
        })
    }
}