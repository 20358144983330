import React, { Component } from 'react';

class Search extends Component {
    render() {
        const {handleForm, base, query} = this.props;
        return (
            <div className="search-cont d-flex align-items-center">
                <select id="search-select" name="base" className="form-control wd-35p" value={base} onChange={handleForm}>
                    <option value="ticket_number">Ticket No</option>
                    <option value="suffix">Suffix</option>
                </select>
                <input type="text" name="query" value={query} onChange={handleForm} placeholder="Search Keywords.." className="form-control wd-350 bd-l-0 mr-2" />     
            </div>
        );
    }
}

export default Search;