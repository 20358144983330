const bulk_state = {
    status: 0,
    content: '',
    ticket_numbers: ''
}

const reset_state = {
    status: 0,
    content: '',
    ticket_numbers: ''
}

const bulk = (state = bulk_state, action) => {
    switch (action.type) {
        case "RESET_STATE_BULK":
            return {
                ...state,
                ...reset_state
            }
		case "PUT_DATA_BULK": 
            return {
                ...state,
                [action.key]: action.data
            }
		default:
			return state;
	}
};

export default bulk;