const kpi_state = {
    all_kpi: false,
    total_reply: 0,
    total_uniq_reply: 0,
    agent_kpi: false,
    agent_name_detail: '',
    date: [new Date(), new Date()]
    // date: []
}

const kpi = (state = kpi_state, action) => {
    switch (action.type) {
        case "PUT_DATA_KPI":
            return {
                ...state,
                [action.key]: action.data
            }
		case "PUT_ALL_KPI":
            return { 
                ...state, 
                all_kpi: action.data 
            };
        case "PUT_AGENT_KPI":
            return { 
                ...state, 
                agent_kpi: action.data
            };
		default:
			return state;
	}
};

export default kpi;