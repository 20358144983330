import React, { Component } from 'react';
import moment from 'moment';

class Own extends Component {
    componentDidMount() {
        const {actions, kpi, user} = this.props;

        let date_from = "",
            date_to = "";

        if (kpi.date.length > 0) {
            date_from = moment(kpi.date[0]).format('YYYY-MM-DD');
            date_to = moment(kpi.date[1]).format('YYYY-MM-DD');
        }

        if (user) {
            actions.agent_kpi(user.email, date_from, date_to, true);
        }

    }

    handleLoop = () => {
        const {kpi} = this.props;

        let list = (
            <tr>
                <td colSpan={3} className="valign-middle tx-center">No Data Found</td>
            </tr>
        )

        if (kpi.agent_kpi && kpi.agent_kpi.list_ticket.length > 0) {
            list = kpi.agent_kpi.list_ticket.map((dt, idx) =>
                <tr key={idx}>
                    <td className="valign-middle tx-center">{idx + 1}</td>
                    <td className="valign-middle tx-center">{dt.ticket_number_id}</td>
                    <td className="valign-middle tx-center">{dt.ticket_reply_count}</td>
                </tr>
            )
        }

        return list;
    }

    render() {
        return (
            <div className="table-responsive mt-3">
                <table className="table mg-b-0 tx-13">
                    <thead>
                        <tr className="tx-10">
                            <th>NO</th>
                            <th>TICKET NO</th>
                            <th>REPLY COUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.handleLoop()}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Own;