import React, { Component } from 'react';

class Modal extends Component {

    handleLoop = () => {
        const {list_ticket} = this.props;

        let list = (
            <tr>
                <td colSpan={2} className="valign-middle tx-center">No Data Found</td>
            </tr>
        )

        if (list_ticket && list_ticket.length > 0) {
            list = list_ticket.map((dt, idx) =>
                <tr key={idx}>
                    <td className="valign-middle tx-center">{idx + 1}</td>
                    <td className="valign-middle tx-center">{dt.ticket_number_id}</td>
                    <td className="valign-middle tx-center">{dt.ticket_reply_count}</td>
                </tr>
            )
        }

        return list;
    }

    handleLoopList = () => {
        const {list_ticket} = this.props;
        let list = "";

        if (list_ticket && list_ticket.length > 0) {
            list_ticket.map((dt) => {
                return list += `${dt.ticket_number},`
            })
        }
        return list
    }

    render() {
        const {
            agent_name,
            total_reply,
            total_uniq_reply
        } = this.props
        return (
            <div id="modal-kpi" className="modal fade">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-y-20 pd-x-25">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Agent KPI</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body pd-25">
                            <div className="row align-items-center">
                                <div className="col-7">
                                    <h5 className="lh-4 tx-inverse">{agent_name}</h5>
                                </div>
                                <div className="col-5">
                                    <div className="d-h-t-right justify-content-end">
                                        <div className="summary-item">
                                            <h1>{total_reply}</h1>
                                            <span>Total<br/>Reply</span>
                                        </div>
                                        <div className="summary-item">
                                            <h1>{total_uniq_reply}</h1>
                                            <span>Total<br/>Unique<br/>Reply</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive mt-3">
                                <table className="table mg-b-0 tx-13">
                                    <thead>
                                        <tr className="tx-10">
                                            <th>NO</th>
                                            <th>TICKET NO</th>
                                            <th>REPLY COUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.handleLoop()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;