const main_state = {
	user: undefined,
	categories: false,
	loader: false,
	nav: 0
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "PUT_NAV": 
			return { ...state, nav: action.data };
		case "PUT_USER":
			return { ...state, user: action.data };
		case "PUT_CATEGORIES":
			return { ...state, categories: action.data }
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data}
		default:
			return state;
	}
};

export default main;