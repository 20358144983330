import React, { Component } from 'react';
import moment from 'moment';

import Table from './Table';
import Modal from './Modal';

class All extends Component {

    componentDidMount() {
        const {actions, kpi} = this.props;

        let date_from = "",
            date_to = "";

        if (kpi.date.length > 0) {
            date_from = moment(kpi.date[0]).format('YYYY-MM-DD');
            date_to = moment(kpi.date[1]).format('YYYY-MM-DD');
        }

        actions.all_kpi(date_from, date_to);
    }

    render() {
        const {kpi, actions} = this.props;
        return (
            <>
                <div className="row pt-3">
                    <div className="col-12">
                        <Table 
                            agent_kpi={actions.agent_kpi} 
                            data={kpi.all_kpi}
                            date={kpi.date}
                        />
                    </div>
                </div>
                <Modal
                    agent_name={kpi.agent_name_detail}
                    data={kpi.agent_kpi ? kpi.agent_kpi.agent_kpi : []}
                    list_ticket={kpi.agent_kpi ? kpi.agent_kpi.list_ticket : []}
                    total_reply={kpi.agent_kpi ? kpi.agent_kpi.total_reply : 0}
                    total_uniq_reply={kpi.agent_kpi ? kpi.agent_kpi.total_uniq_reply : 0}
                />
            </>
        );
    }
}

export default All;