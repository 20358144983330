import React, { Component } from 'react';
import PartialModal from 'partial/PartialModal';
import ReactQuill from 'react-quill';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'redux/actions/bulk';

class ConfirmModal extends Component {

    populateTicketNumbers = () => {
        const {ticket_numbers} = this.props.bulk;
        let data = ticket_numbers.split(",");
        return data.map((dt, idx) => 
            <div key={idx} className="wd-30p mr-2">{dt}</div>
        )
    }

    mapStatus = () => {
        const {status} = this.props.bulk;
        const map = ["-", "Open", "In Progress", "Closed"]
        return map[status]
    }

    render() {
        const {content} = this.props.bulk;
        return (
            <PartialModal id="modal-bulk" title="Are you sure ?" bodyClass="pd-30" dialogClass="wd-50p" footer={false} handleSubmit={this.props.handleSubmit}>
                <label className="section-title mt-0">Ticket Numbers</label>
                <div className="d-flex flex-wrap ">
                    {this.populateTicketNumbers()}
                </div>

                <label className="section-title mt-4">Status</label>
                <div>{this.mapStatus()}</div>

                <label className="section-title mt-4">Reply</label>
                <ReactQuill 
                    value={content} 
                    readOnly={true}
                    theme={"bubble"} 
                />
            </PartialModal>
        )
    }
}

function mapStateToProps(state) {
	return {
        bulk: state.bulk
    }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);