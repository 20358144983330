import moment from 'moment';

export const getUrlParam = (pa) => {
    var url = window.location.href.replace(/#+.*$/, ),
        params = url.substring(url.indexOf("?") + 1, url.length).split("&"),
        param = {};

    for (var i = 0; i < params.length; i++) {
        var pos = params[i].indexOf('='),
            key = params[i].substring(0, pos),
            val = params[i].substring(pos + 1);

            
        // param[key] = val;
        param[key] = val.replace('undefined', '');
    }

    return (typeof (param[pa]) === "undefined" || param[pa] === '') ? false : param[pa];
}

export const removeParamUrl = () => {
    const location = window.location;
    window.history.pushState({}, document.title, location.origin + location.pathname);
}

export const language = (lang,json_key) =>{
    const json_data = require(`lang/${lang}`);
    return json_data['data'][0][json_key];
}

export const date_parser = (date) => {
    return moment(date).format('DD MMM YYYY HH:mm:ss');
}