import React, { Component } from 'react';

class PartialModal extends Component {
    render() {
        return (
            <div id={this.props.id} className={"modal fade " + this.props.containerClass} data-backdrop="static" tabIndex='-1'>
                <div className={"modal-dialog modal-dialog-vertical-center modal-lg " + this.props.dialogClass} role="document">
                    <form onSubmit={this.props.handleSubmit} >
                    <div className={"modal-content bd-0 tx-14 " + this.props.contentClass}>
                        {
                            (!this.props.title) ? '' :
                            (
                                <div className="modal-header">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{this.props.title}</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )
                        }
                        <div className={"modal-body pd-25 " + this.props.bodyClass}>
                            {this.props.children}
                        </div>
                        {
                            (!this.props.footer) ? 
                            (
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            ) : ""
                        }
                    </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PartialModal;