import React, { Component } from 'react';
import moment from 'moment';

import Title from 'partial/Title';
import All from './components/All';
import Own from './components/Own';

import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'redux/actions/kpi';

class KPI extends Component {

    handleFilter = () => {
        const {actions, kpi, user} = this.props;
        let date_from = "",
            date_to = "";

        if (kpi.date.length > 0) {
            date_from = moment(kpi.date[0]).format('YYYY-MM-DD');
            date_to = moment(kpi.date[1]).format('YYYY-MM-DD');
        }

        if (user.position.id === 1) {
            actions.all_kpi(date_from, date_to);
        } else {
            actions.agent_kpi(user.email, date_from, date_to, true);
        }
    }

    renderContent = () => {
        const {user} = this.props;
        
        if (user) {
            if (user.position.id === 1) {
                return <All {...this.props} />
            }
            else
            {
                return <Own {...this.props} />
            }
        }
        
    }

    render() {
        const {kpi, actions} = this.props;
        
        return (
            <div id="kpi" className="mb-4">
                <Title>KPI</Title>
                <div className="row align-items-center">
                    <div className="col-7">
                        <DateRangePicker 
                            placeholder="Select Date Range"
                            value={kpi.date}
                            onChange={ value => actions.put_data_kpi('date', value) }
                        />
                        <button 
                            className="btn btn-primary btn-sm ml-3"
                            onClick={this.handleFilter}
                        >Filter</button>
                    </div>
                    <div className="col-5">
                        <div className="d-h-t-right justify-content-end">
                            <div className="summary-item">
                                <h1>{kpi.total_reply}</h1>
                                <span>Total<br/>Reply</span>
                            </div>
                            {/* <div className="summary-item">
                                <h1>{kpi.total_uniq_reply}</h1>
                                <span>Total<br/>Unique<br/>Reply</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                {this.renderContent()}
            </div>
        );
    }
}

function mapStateToProps(state) {
	return { 
        kpi: state.kpi,
        user: state.main.user
    }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(KPI);