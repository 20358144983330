import React, { Component } from 'react'
import ReactQuill from 'react-quill';
import Title from 'partial/Title';
import ConfirmModal from './components/ConfirmModal';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'redux/actions/bulk';

const $ = window.$;

class BulkReply extends Component {

    constructor(props) {
        super(props);
        this.quillRef = null;      // Quill instance
        this.reactQuillRef = null; // ReactQuill component
    }

    componentDidMount() {
        this.attachQuillRefs()
    }
    
    componentDidUpdate() {
        this.attachQuillRefs()
    }

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    handleChange = (type, e) => {
        const {actions} = this.props;
        actions.put_data(type, e.currentTarget.value);
    }

    handleQuill = dt => {
        const {actions} = this.props;
        actions.put_data('content', dt);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        $('#modal-bulk').modal();
    }

    handleSubmitModal = (e) => {
        e.preventDefault()
        $('#modal-bulk').modal('hide');
        const {status, content, ticket_numbers} = this.props.bulk;
        const {actions} = this.props;

        let formdata = new FormData();
        formdata.append('ticket_numbers', ticket_numbers);
        formdata.append('status', status);
        formdata.append('content', content);
        actions.batch_add_conversation(formdata, this.quillRef);
    }

    render() {
        return (
            <div id="bulk_reply">
                <Title>Bulk Reply</Title>
                <form className="section-wrapper" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md">
                            <label className="section-title">Ticket Numbers <span className="tx-8 tx-thin">separated by comma</span></label>                            
                            <textarea 
                                rows="3" 
                                name="ticket_ids" 
                                className="form-control" 
                                placeholder="Input ticket id separated by comma" 
                                required 
                                onKeyUp={(e) => this.handleChange('ticket_numbers', e)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group bd-t-0-force">
                                <label className="section-title mt-3">Status</label>
                                <select id="status-select" name="status" className="form-control wd-200" onChange={(e) => this.handleChange('status', e)} required>
                                    <option value="">-- Select Status --</option>
                                    <option value="1">Open</option>
                                    <option value="2">In Progress</option>
                                    <option value="3">Closed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <label className="section-title mt-1">Reply Ticket</label>
                            <ReactQuill 
                                ref={(el) => { this.reactQuillRef = el }}
                                placeholder="Insert reply here..."
                                onChange={(dt) => this.handleQuill(dt)} 
                            />
                        </div>
                    </div>
                    <div className="button-cont mt-2 d-flex justify-content-end align-items-center">
                        <button type="submit" className="btn btn-primary wd-100">Send</button>
                    </div>
                </form>
                <ConfirmModal handleSubmit={this.handleSubmitModal} />
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        bulk: state.bulk
    }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkReply);