import React, { Component } from 'react';

class Title extends Component {
    render() {
        return (
            <div className="slim-pageheader flex-row">
                <h6 className="slim-pagetitle" style={{textTransform: "uppercase"}}>{this.props.children}</h6>
            </div>
        );
    }
}

export default Title;